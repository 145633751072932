import React, { useEffect, useState } from "react"

export default function Section3() {
  const [slideUpLeft, setSlideUpLeft] = useState("slide-left")
  const [slideDownRight, setSlideDownRight] = useState("slide-right")

  useEffect(() => {
    setSlideUpLeft(window.outerWidth < 500 ? "slide-up" : "slide-left")
    setSlideDownRight(window.outerWidth < 500 ? "slide-down" : "slide-right")
  }, [])

  return (
    <div id="process">
      <h1 className="title title-white">Just leave it for us...</h1>

      <div className="products-section">
        <div className="product-section">
          <div
            data-sal={slideDownRight}
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="1200"
          >
            <h2 className="title-white">Discovering</h2>
            <p className="p-white">
              At Sysdone, a strong emphasis is placed on the discovery phase. We
              make sure to cover your entire workflow and to account for your
              future growth. We review your current business process identifying
              ways to automate your day-to-day tasks, and solve your complex
              business needs.
            </p>
          </div>
          <div className="product-svg-right product-svg">
            <img
              src={require("../../img/bg-planning.svg")}
              className="bg-svg bg-right"
              alt="svg"
              data-sal={slideUpLeft}
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="1200"
            />
            <div
              className="bg-content-div"
              data-sal={slideUpLeft}
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-duration="1200"
            >
              <img
                src={require("../../img/map.svg")}
                className="bg-content-svg"
                alt="svg"
              />
              <img
                src={require("../../img/rocket.svg")}
                className="bg-content-svg"
                alt="svg"
              />
            </div>
          </div>
        </div>
        <div className="product-section">
          <div
            data-sal={slideUpLeft}
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="1200"
          >
            <h2 className="title-white">Planning</h2>
            <p className="p-white">
              In this phase, we go hunting for the best code stack suited for
              your project. We're looking for the best architecture and hosting
              options where your system will happily live. Stability,
              scalability, security, and cost are some of the parameters we use
              to determine the best options for you.
            </p>
          </div>
          <div className="product-svg-left product-svg">
            <img
              src={require("../../img/bg-designing.svg")}
              className="bg-svg bg-left"
              alt="svg"
              data-sal={slideDownRight}
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="1200"
            />
            <div
              className="bg-content-div"
              style={{ marginTop: -240 }}
              data-sal={slideDownRight}
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-duration="1200"
            >
              <img
                src={require("../../img/tools.svg")}
                className="bg-content-svg"
                alt="svg"
              />
              <img
                src={require("../../img/man_lift.svg")}
                className="bg-content-svg"
                alt="svg"
              />
            </div>
          </div>
        </div>
        <div className="product-section">
          <div
            data-sal={slideDownRight}
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="1200"
          >
            <h2 className="title-white">Developing</h2>
            <p className="p-white">
              This is where the exciting part begins.
              <br />
              Well, coding it is! Things tend to get real here. We develop the
              system piece by piece, component by component. From coding to
              testing, back to coding and yet to testing again. Many cycles
              until it's polished and ready to be presented to you.
            </p>
          </div>
          <div className="product-svg-right product-svg">
            <img
              src={require("../../img/bg-developing.svg")}
              className="bg-svg bg-right"
              alt="svg"
              data-sal={slideUpLeft}
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="1200"
            />
            <div
              className="bg-content-div"
              data-sal={slideUpLeft}
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-duration="1200"
            >
              <img
                src={require("../../img/man_working.svg")}
                className="bg-content-svg"
                alt="svg"
              />
              <img
                src={require("../../img/Tulip.svg")}
                className="bg-content-svg"
                alt="svg"
              />
            </div>
          </div>
        </div>
        <div className="product-section">
          <div
            data-sal={slideUpLeft}
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="1200"
          >
            <h2 className="title-white">Implementing</h2>
            <p className="p-white">
              After robust testing, we deploy the system for production use.
              This includes setting up the live server; connecting your desired
              domain; training your staff; bringing in your existing database;
              setting up backup and securing the system.
              <br />
              At Sysdone, we make sure your final product is covered from all
              angles to your utmost satisfaction.
            </p>
          </div>
          <div className="product-svg-left product-svg">
            <img
              src={require("../../img/bg-implementation.svg")}
              className="bg-svg bg-right"
              alt="svg"
              data-sal={slideDownRight}
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="1200"
            />
            <div
              className="bg-content-div"
              style={{ marginTop: -320 }}
              data-sal={slideDownRight}
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-duration="1200"
            >
              <img
                src={require("../../img/Computer.svg")}
                className="bg-content-svg"
                alt="svg"
              />
              <img
                src={require("../../img/man_with_documents.svg")}
                className="bg-content-svg"
                alt="svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
